import React, { useEffect, useState } from "react"
import { Button, Spin, Table, InputNumber, Space, Popconfirm } from "antd"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../../components/notification"
import ModalSelectCollectionMedium from "./ModalSelectCollectionMedium"
import UpdateFeeMedium from "./UpdateFeeMedium"
import UpdateFeeBig from "./UpdateFeeBig"
import "../style.scss"
import { updateTaxFee } from "../../../../services/taxFee"
import ModalSelectCollectionMin from "./ModalSelectCollectionMin"
import UpdateFeeMin from "./UpdateFeeMin"
import useWindowDimensions from "../../../../hooks/GetWidthHeightWindow/useWindowDimensions"
import { updateCollectionsByStore } from "../../../../services/user"
import textAndRules from "../../../../utils/textAndRules"

function Applicationfee(props) {
  const [feeValue, setFeeValue] = useState(null)
  const [listDataSourceOfTable, setListDataSourceOfTable] = useState([])
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    if (props.listColections.length > 0) {
      const arrayConvert = []
      props.listColections.map((el) => {
        const otherProductCollectionArrayConvert = el.other_product_collection?.map(
          (element) => {
            if (props.type == "wholesale") {
              const otherProductCollectionArrayConvert = element.other_product_collection?.map(
                (elementMin) => {
                  return {
                    title: elementMin.title,
                    id: elementMin.id,
                    is_custom_application_fee_wholesale: elementMin
                      .ApplicationFee?.[0]
                      ? elementMin.ApplicationFee?.[0]?.isCustom
                      : false,
                    application_fee_wholesale_default: elementMin
                      .ApplicationFee?.[0]
                      ? elementMin.ApplicationFee?.[0]?.fee
                      : element.ApplicationFee?.[0]
                      ? element.ApplicationFee?.[0]?.fee
                      : el.ApplicationFee?.[0]
                      ? el.ApplicationFee?.[0]?.fee
                      : elementMin.application_fee_wholesale_default,
                    metadata: elementMin?.ApplicationFee?.[0],
                  }
                }
              )
              return {
                title: element.title,
                id: element.id,
                is_custom_application_fee_wholesale: element.ApplicationFee?.[0]
                  ? element.ApplicationFee?.[0]?.isCustom
                  : false,
                application_fee_wholesale_default: element.ApplicationFee?.[0]
                  ? element.ApplicationFee?.[0]?.fee
                  : el.ApplicationFee?.[0]
                  ? el.ApplicationFee?.[0]?.fee
                  : element.application_fee_wholesale_default,
                metadata: element.ApplicationFee?.[0],
                other_product_collection: otherProductCollectionArrayConvert,
              }
            } else {
              const otherProductCollectionArrayConvert = element.other_product_collection?.map(
                (elementMin) => {
                  return {
                    title: elementMin.title,
                    id: elementMin.id,
                    is_custom_application_fee_retail: elementMin
                      .ApplicationFee?.[0]
                      ? elementMin.ApplicationFee?.[0]?.isCustom
                      : false,
                    application_fee_retail_default: elementMin
                      .ApplicationFee?.[0]
                      ? elementMin.ApplicationFee?.[0]?.fee
                      : element.ApplicationFee?.[0]
                      ? element.ApplicationFee?.[0]?.fee
                      : el.ApplicationFee?.[0]
                      ? el.ApplicationFee?.[0]?.fee
                      : elementMin.application_fee_retail_default,
                    metadata: elementMin?.ApplicationFee?.[0],
                  }
                }
              )
              return {
                title: element.title,
                id: element.id,
                is_custom_application_fee_retail: element.ApplicationFee?.[0]
                  ? element.ApplicationFee?.[0]?.isCustom
                  : false,
                application_fee_retail_default: element.ApplicationFee?.[0]
                  ? element.ApplicationFee?.[0]?.fee
                  : el.ApplicationFee?.[0]
                  ? el.ApplicationFee?.[0]?.fee
                  : element.application_fee_retail_default,
                metadata: element.ApplicationFee?.[0],
                other_product_collection: otherProductCollectionArrayConvert,
              }
            }
          }
        )
        arrayConvert.push({
          title: el.title,
          id: el.id,
          is_custom_application_fee: el.ApplicationFee?.[0] ? true : false,
          metadata: el.ApplicationFee?.[0],
          application_fee_default: el.ApplicationFee?.[0]
            ? el.ApplicationFee?.[0]?.fee
            : props.type == "wholesale"
            ? el.application_fee_wholesale_default
            : el.application_fee_retail_default,
          items: otherProductCollectionArrayConvert,
        })
      })

      props.listColections.map((el) => {
        const arrayReplace = []
        const otherProductCollectionArrayConvert = el.other_product_collection?.map(
          (element) => {
            if (props.type == "wholesale") {
              const otherProductCollectionArrayConvert = element.other_product_collection?.map(
                (elementMin) => {
                  return {
                    title: elementMin.title,
                    id: elementMin.id,
                    is_custom_application_fee_wholesale: elementMin
                      .ApplicationFee?.[0]
                      ? elementMin.ApplicationFee?.[0]?.isCustom
                      : false,
                    application_fee_wholesale_default: elementMin
                      .ApplicationFee?.[0]
                      ? elementMin.ApplicationFee?.[0]?.fee
                      : element.ApplicationFee?.[0]
                      ? element.ApplicationFee?.[0]?.fee
                      : el.ApplicationFee?.[0]
                      ? el.ApplicationFee?.[0]?.fee
                      : elementMin.application_fee_wholesale_default,
                    metadata: elementMin?.ApplicationFee?.[0],
                  }
                }
              )
              return {
                title: element.title,
                id: element.id,
                is_custom_application_fee_wholesale: element.ApplicationFee?.[0]
                  ? element.ApplicationFee?.[0]?.isCustom
                  : false,
                application_fee_wholesale_default: element.ApplicationFee?.[0]
                  ? element.ApplicationFee?.[0]?.fee
                  : el.ApplicationFee?.[0]
                  ? el.ApplicationFee?.[0]?.fee
                  : element.application_fee_wholesale_default,
                metadata: element.ApplicationFee?.[0],
                other_product_collection: otherProductCollectionArrayConvert,
              }
            } else {
              const otherProductCollectionArrayConvert = element.other_product_collection?.map(
                (elementMin) => {
                  return {
                    title: elementMin.title,
                    id: elementMin.id,
                    is_custom_application_fee_retail: elementMin
                      .ApplicationFee?.[0]
                      ? elementMin.ApplicationFee?.[0]?.isCustom
                      : false,
                    application_fee_retail_default: elementMin
                      .ApplicationFee?.[0]
                      ? elementMin.ApplicationFee?.[0]?.fee
                      : element.ApplicationFee?.[0]
                      ? element.ApplicationFee?.[0]?.fee
                      : el.ApplicationFee?.[0]
                      ? el.ApplicationFee?.[0]?.fee
                      : elementMin.application_fee_retail_default,
                    metadata: elementMin?.ApplicationFee?.[0],
                  }
                }
              )
              return {
                title: element.title,
                id: element.id,
                is_custom_application_fee_retail: element.ApplicationFee?.[0]
                  ? element.ApplicationFee?.[0]?.isCustom
                  : false,
                application_fee_retail_default: element.ApplicationFee?.[0]
                  ? element.ApplicationFee?.[0]?.fee
                  : el.ApplicationFee?.[0]
                  ? el.ApplicationFee?.[0]?.fee
                  : element.application_fee_retail_default,
                metadata: element.ApplicationFee?.[0],
                other_product_collection: otherProductCollectionArrayConvert,
              }
            }
          }
        )
        el.other_product_collection?.map((ele) => {
          if (ele.ApplicationFee?.length > 0) {
            arrayReplace.push({
              title: el.title,
              id: el.id,
              is_custom_application_fee: el.ApplicationFee?.[0] ? true : false,
              metadata: el.ApplicationFee?.[0],
              application_fee_default: el.ApplicationFee?.[0]
                ? el.ApplicationFee?.[0]?.fee
                : props.type == "wholesale"
                ? el.application_fee_wholesale_default
                : el.application_fee_retail_default,
              items: otherProductCollectionArrayConvert,
              idMedium: ele.id,
              is_custom_application_fee_medium: true,
              application_fee_default_medium: ele.ApplicationFee?.[0]?.fee,
              titleMedium: ele.title,
              metadataMedium: ele.ApplicationFee?.[0],
            })
          }
        })

        if (
          arrayReplace.length > 0 &&
          arrayReplace.length == el.other_product_collection?.length
        ) {
          const indexOfEl = arrayConvert
            .map((element) => {
              return element.id
            })
            .indexOf(el.id)
          arrayConvert.splice(indexOfEl, 1, ...arrayReplace)
        }
        if (
          arrayReplace.length > 0 &&
          arrayReplace.length < el.other_product_collection?.length
        ) {
          const arrayReplace2 = [
            ...arrayReplace,
            {
              title: el.title,
              id: el.id,
              is_custom_application_fee: el.ApplicationFee?.[0] ? true : false,
              metadata: el.ApplicationFee?.[0],
              application_fee_default: el.ApplicationFee?.[0]
                ? el.ApplicationFee?.[0]?.fee
                : props.type == "wholesale"
                ? el.application_fee_wholesale_default
                : el.application_fee_retail_default,
              items: otherProductCollectionArrayConvert,
            },
          ]
          const indexOfEl = arrayConvert
            .map((element) => {
              return element.id
            })
            .indexOf(el.id)
          arrayConvert.splice(indexOfEl, 1, ...arrayReplace2)
        }
      })

      props.listColections.map((el) => {
        el.other_product_collection?.map((ele) => {
          const arrayReplace = []
          const otherProductCollectionArrayConvert = el.other_product_collection?.map(
            (element) => {
              if (props.type == "wholesale") {
                const otherProductCollectionArrayConvert = element.other_product_collection?.map(
                  (elementMin) => {
                    return {
                      title: elementMin.title,
                      id: elementMin.id,
                      is_custom_application_fee_wholesale: elementMin
                        .ApplicationFee?.[0]
                        ? elementMin.ApplicationFee?.[0]?.isCustom
                        : false,
                      application_fee_wholesale_default: elementMin
                        .ApplicationFee?.[0]
                        ? elementMin.ApplicationFee?.[0]?.fee
                        : element.ApplicationFee?.[0]
                        ? element.ApplicationFee?.[0]?.fee
                        : el.ApplicationFee?.[0]
                        ? el.ApplicationFee?.[0]?.fee
                        : elementMin.application_fee_wholesale_default,
                      metadata: elementMin?.ApplicationFee?.[0],
                    }
                  }
                )
                return {
                  title: element.title,
                  id: element.id,
                  is_custom_application_fee_wholesale: element
                    .ApplicationFee?.[0]
                    ? element.ApplicationFee?.[0]?.isCustom
                    : false,
                  application_fee_wholesale_default: element.ApplicationFee?.[0]
                    ? element.ApplicationFee?.[0]?.fee
                    : el.ApplicationFee?.[0]
                    ? el.ApplicationFee?.[0]?.fee
                    : element.application_fee_wholesale_default,
                  metadata: element.ApplicationFee?.[0],
                  other_product_collection: otherProductCollectionArrayConvert,
                }
              } else {
                const otherProductCollectionArrayConvert = element.other_product_collection?.map(
                  (elementMin) => {
                    return {
                      title: elementMin.title,
                      id: elementMin.id,
                      is_custom_application_fee_retail: elementMin
                        .ApplicationFee?.[0]
                        ? elementMin.ApplicationFee?.[0]?.isCustom
                        : false,
                      application_fee_retail_default: elementMin
                        .ApplicationFee?.[0]
                        ? elementMin.ApplicationFee?.[0]?.fee
                        : element.ApplicationFee?.[0]
                        ? element.ApplicationFee?.[0]?.fee
                        : el.ApplicationFee?.[0]
                        ? el.ApplicationFee?.[0]?.fee
                        : elementMin.application_fee_retail_default,
                      metadata: elementMin?.ApplicationFee?.[0],
                    }
                  }
                )
                return {
                  title: element.title,
                  id: element.id,
                  is_custom_application_fee_retail: element.ApplicationFee?.[0]
                    ? element.ApplicationFee?.[0]?.isCustom
                    : false,
                  application_fee_retail_default: element.ApplicationFee?.[0]
                    ? element.ApplicationFee?.[0]?.fee
                    : el.ApplicationFee?.[0]
                    ? el.ApplicationFee?.[0]?.fee
                    : element.application_fee_retail_default,
                  metadata: element.ApplicationFee?.[0],
                  other_product_collection: otherProductCollectionArrayConvert,
                }
              }
            }
          )
          ele.other_product_collection?.map((element) => {
            if (element.ApplicationFee?.length > 0) {
              arrayReplace.push({
                title: el.title,
                id: el.id,
                is_custom_application_fee: el.ApplicationFee?.[0]
                  ? true
                  : false,
                metadata: el.ApplicationFee?.[0],
                application_fee_default: el.ApplicationFee?.[0]
                  ? el.ApplicationFee?.[0]?.fee
                  : props.type == "wholesale"
                  ? el.application_fee_wholesale_default
                  : el.application_fee_retail_default,
                items: otherProductCollectionArrayConvert,
                idMedium: ele.id,
                titleMedium: ele.title,
                metadataMedium: ele.ApplicationFee?.[0],
                is_custom_application_fee_medium: ele.ApplicationFee?.[0]
                  ? true
                  : false,
                application_fee_default_medium: ele.ApplicationFee?.[0]
                  ? ele.ApplicationFee?.[0]?.fee
                  : el.ApplicationFee?.[0]
                  ? el.ApplicationFee?.[0]?.fee
                  : props.type == "wholesale"
                  ? ele.application_fee_wholesale_default
                  : ele.application_fee_retail_default,
                idMin: element.id,
                is_custom_application_fee_min: true,
                application_fee_default_min: element?.ApplicationFee?.[0]?.fee,
                titleMin: element.title,
                metadataMin: element.ApplicationFee?.[0],
              })
            }
          })

          if (
            arrayReplace.length > 0 &&
            arrayReplace.length == ele.other_product_collection?.length
          ) {
            const indexOfEl = arrayConvert
              .map((element) => {
                return element.idMedium
              })
              .indexOf(el.idMedium)
            arrayConvert.splice(indexOfEl, 1, ...arrayReplace)
          }
          if (
            arrayReplace.length > 0 &&
            arrayReplace.length < el.other_product_collection?.length
          ) {
            const arrayReplace2 = [
              ...arrayReplace,
              {
                title: el.title,
                id: el.id,
                is_custom_application_fee: el.ApplicationFee?.[0]
                  ? true
                  : false,
                metadata: el.ApplicationFee?.[0],
                application_fee_default: el.ApplicationFee?.[0]
                  ? el.ApplicationFee?.[0]?.fee
                  : props.type == "wholesale"
                  ? el.application_fee_wholesale_default
                  : el.application_fee_retail_default,
                items: otherProductCollectionArrayConvert,
                idMedium: ele.id,
                titleMedium: ele.title,
                metadataMedium: ele.ApplicationFee?.[0],
                is_custom_application_fee_medium: ele.ApplicationFee?.[0]
                  ? true
                  : false,
                application_fee_default_medium: ele.ApplicationFee?.[0]
                  ? ele.ApplicationFee?.[0]?.fee
                  : el.ApplicationFee?.[0]
                  ? el.ApplicationFee?.[0]?.fee
                  : props.type == "wholesale"
                  ? ele.application_fee_wholesale_default
                  : ele.application_fee_retail_default,
              },
            ]
            const indexOfEl = arrayConvert
              .map((element) => {
                return element.idMedium
              })
              .indexOf(ele.id)

            if (indexOfEl >= 0) {
              arrayConvert.splice(indexOfEl, 1, ...arrayReplace2)
            } else {
              const indexOfRecord = arrayConvert
                .map((element) => {
                  return element.id
                })
                .indexOf(el.id)
              const arrayRecordSameId = arrayConvert.filter(
                (eleeee) => eleeee.id == el.id
              )
              const lengthRowHaveSameId = arrayRecordSameId.length

              arrayConvert.splice(
                indexOfRecord + lengthRowHaveSameId - 1,
                0,
                ...arrayReplace2
              )
            }
          }
        })
      })

      setListDataSourceOfTable(arrayConvert)
    }
  }, [props.listColections])

  const columns = [
    {
      title: <div style={{ fontSize: 12 }}>{"大カテゴリ"}</div>,
      dataIndex: "title",
      width: 280,
      render: (_, record, index) => {
        const obj = {
          children: record.title,
          props: {},
        }
        if (index == 0) {
          const length = listDataSourceOfTable.filter(
            (el) => el.id == record.id
          ).length
          obj.props.rowSpan = length
        }
        if (index > 0) {
          if (
            listDataSourceOfTable[index].title ==
            listDataSourceOfTable[index - 1].title
          ) {
            obj.props.rowSpan = 0
          } else {
            const length2 = listDataSourceOfTable.filter(
              (el) => el.title == record.title
            ).length
            obj.props.rowSpan = length2
          }
        }
        return obj
      },
    },
    {
      title: (
        <div
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className={"fixInput"}
        >
          <div>{"手数料率"}</div>
          <Space size={5} direction="horizontal">
            <InputNumber
              style={{ height: 25 }}
              type="number"
              min={0}
              max={100}
              value={feeValue}
              onChange={(value) => {
                setFeeValue(value)
              }}
            />
            <div>{"%"}</div>
            <Popconfirm
              disabled={!feeValue}
              placement="bottom"
              title={
                <div style={{ fontSize: 14, fontWeight: 600 }}>
                  {
                    "確認ボタンを押下するとすべての製品の手数料が変更されます。変更してもよろしいですか? "
                  }
                </div>
              }
              onConfirm={async () => {
                props.setIsLoading(true)

                const { response } = await updateCollectionsByStore({
                  storeId: props.storeId,
                  fee: feeValue,
                  isAll: true,
                  applicationType: props.type,
                })
                if (response.status == 200) {
                  props.setIsLoading(false)

                  props.getListColections()
                  openNotificationChangeSuccess(
                    textAndRules.updateSuccess,
                    "",
                    "#389e0d"
                  )
                } else {
                  props.setIsLoading(false)

                  openNotificationFail(
                    "ただいま、サーバが混み合っています。",
                    "しばらくしてから再度操作してください。",
                    "#f81d22"
                  )
                }
              }}
              okText="確認"
              cancelText="キャンセル"
            >
              <Button
                style={{ height: 25, fontSize: 10 }}
                disabled={!feeValue}
                danger
              >
                {"一括設定"}
              </Button>
            </Popconfirm>
          </Space>
        </div>
      ),
      width: 220,

      render: (_, record, index) => {
        const obj = {
          children: (
            <UpdateFeeBig
              setListColections={props.setListColections}
              record={record}
              getListColections={props.getListColections}
              listDataSourceOfTable={listDataSourceOfTable}
              setListDataSourceOfTable={setListDataSourceOfTable}
              listColections={props.listColections}
              storeId={props.storeId}
              type={props.type}
            />
          ),
          props: {},
        }
        if (index == 0) {
          const length = listDataSourceOfTable.filter(
            (el) => el.title == record.title
          ).length
          obj.props.rowSpan = length
        }
        if (index > 0) {
          if (
            listDataSourceOfTable[index].title ==
            listDataSourceOfTable[index - 1].title
          ) {
            obj.props.rowSpan = 0
          } else {
            const length2 = listDataSourceOfTable.filter(
              (el) => el.title == record.title
            ).length
            obj.props.rowSpan = length2
          }
        }
        return obj
      },
    },
    {
      title: <div style={{ fontSize: 12 }}>{"中カテゴリ"}</div>,

      render: (_, record, index) => {
        const obj = {
          children: record.titleMedium ? (
            <div>{record.titleMedium}</div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>{"カテゴリ設定"}</div>
              <div>
                <ModalSelectCollectionMedium
                  record={record}
                  setListDataSourceOfTable={setListDataSourceOfTable}
                  listDataSourceOfTable={listDataSourceOfTable}
                  type={props.type}
                />
              </div>
            </div>
          ),
          props: {},
        }
        if (record.titleMedium) {
          if (index == 0) {
            const length = listDataSourceOfTable.filter(
              (el) => el.titleMedium == record.titleMedium
            ).length
            obj.props.rowSpan = length
            // console.log("obj", obj)
          }
          if (index > 0) {
            if (
              listDataSourceOfTable[index].titleMedium ==
              listDataSourceOfTable[index - 1].titleMedium
            ) {
              obj.props.rowSpan = 0
            } else {
              const length2 = listDataSourceOfTable.filter(
                (el) => el.titleMedium == record.titleMedium
              ).length
              obj.props.rowSpan = length2
            }
          }
        }
        return obj
      },
    },
    {
      title: (
        <div
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>{"手数料率"}</div>
        </div>
      ),
      width: 220,
      render: (_, record, index) => {
        const obj = {
          children: record.titleMedium ? (
            <UpdateFeeMedium
              record={record}
              setListDataSourceOfTable={setListDataSourceOfTable}
              listDataSourceOfTable={listDataSourceOfTable}
              listColections={props.listColections}
              storeId={props.storeId}
              type={props.type}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            ></div>
          ),
          props: {},
        }
        if (record.titleMedium) {
          if (index == 0) {
            const length = listDataSourceOfTable.filter(
              (el) => el.titleMedium == record.titleMedium
            ).length
            obj.props.rowSpan = length
            // console.log("obj", obj)
          }
          if (index > 0) {
            if (
              listDataSourceOfTable[index].titleMedium ==
              listDataSourceOfTable[index - 1].titleMedium
            ) {
              obj.props.rowSpan = 0
            } else {
              const length2 = listDataSourceOfTable.filter(
                (el) => el.titleMedium == record.titleMedium
              ).length
              obj.props.rowSpan = length2
            }
          }
        }
        return obj
      },
    },
    {
      title: <div style={{ fontSize: 12 }}>{"小カテゴリ"}</div>,

      render: (_, record) => {
        if (record.titleMedium) {
          if (record.titleMin) {
            return <div>{record.titleMin}</div>
          }
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>{"カテゴリ設定"}</div>
              <div>
                <ModalSelectCollectionMin
                  record={record}
                  setListDataSourceOfTable={setListDataSourceOfTable}
                  listDataSourceOfTable={listDataSourceOfTable}
                  type={props.type}
                />
              </div>
            </div>
          )
        }
      },
    },
    {
      title: (
        <div
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>{"手数料率"}</div>
          {/* <Space size={5} direction="horizontal">
            <Input style={{ width: 46, height: 25 }} />
            <div>{"%"}</div>
            <Button style={{ height: 25, fontSize: 10 }} danger>
              {"一括設定"}
            </Button>
          </Space> */}
        </div>
      ),
      width: 220,

      render: (_, record) => {
        if (record.idMedium && record.idMin) {
          return (
            <UpdateFeeMin
              record={record}
              setListDataSourceOfTable={setListDataSourceOfTable}
              storeId={props.storeId}
              listDataSourceOfTable={listDataSourceOfTable}
              type={props.type}
            />
          )
        }
      },
    },
  ]

  return (
    <div
      style={{
        width: "100%",
        background: "#FFFFFF",
        borderRadius: "6px",
      }}
    >
      <Spin spinning={props.isLoading}>
        {props.isLoading && props.listColections.length == 0 && (
          <div style={{ height: 500, width: "100%" }}></div>
        )}
        {props.listColections.length > 0 && (
          <Table
            size="small"
            scroll={{ x: 1500, y: height - 355 }}
            columns={columns}
            dataSource={listDataSourceOfTable}
            pagination={false}
            rowKey="index"
          />
        )}
      </Spin>
    </div>
  )
}

export default Applicationfee
