import { Col, Row } from "antd"
import React from "react"
import RowInfo from "../../../customComponents/inquiryManagement/RowInfo"

const CompanyInforContact = ({ title, data }) => {
  return (
    <div
      style={{
        borderTop: "1px solid rgba(0, 0, 0, 0.2)",
        borderRight: "1px solid rgba(0, 0, 0, 0.2)",
      }}
    >
      <Row>
        <Col span={3} className="titleLeft">
          <div>{title}</div>
        </Col>
        <Col span={21}>
          {data &&
            data.map((item, index) => (
              <RowInfo
                key={index}
                textTitle={item.textTitle}
                textContent={item.textContent}
              />
            ))}
        </Col>
      </Row>
    </div>
  )
}

export default CompanyInforContact
