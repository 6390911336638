import { Breadcrumb, Tabs, Typography } from "antd"
import { Link, navigate } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import {
  getCollectionsByStore,
  getCollectionsFeeDefault,
  getStoreById,
} from "../../../services/user"

import HeaderTab from "./HeaderTab"
import TableDetail from "./TableDetail"
import { Content } from "antd/lib/layout/layout"
import ApplicationfeeAll from "./ApplicationfeeAll"
import { GetListTaxFee } from "../../../services/taxFee"
import Applicationfee from "./Applicationfee"

const { TabPane } = Tabs

const Setting = ({ storeId }) => {
  const [store, setStore] = useState({
    name_company: "",
    dataInfo: [],
  })
  const [activeKey, setActiveKey] = useState("1")

  const [isLoading, setIsLoading] = useState(false)
  const [listColections, setListColections] = useState([])

  const getListColections = useCallback(async () => {
    if (storeId) {
      if (storeId === "all") {
        setIsLoading(true)

        const { response } = await GetListTaxFee()
        if (response.status == 200) {
          setListColections(response.data.collections)
          setIsLoading(false)
        }
      } else {
        setIsLoading(true)
        const { response } = await getCollectionsByStore({
          storeId: storeId,
          applicationType: activeKey == "1" ? "wholesale" : "retail",
        })
        if (response.status == 200) {
          setListColections(response.data.collections)
          setIsLoading(false)
        }
      }
    }
  }, [storeId, activeKey])
  const getInfoStore = useCallback(async () => {
    console.log(storeId)
    if (storeId) {
      if (storeId && storeId !== "all" && !store?.name_company) {
        const { response } = await getStoreById(storeId)
        if (response?.data?.stores) {
          const { stores } = response.data
          const dataInfo = [
            {
              textTitle: "企業名",
              textContent: stores.name,
            },
            {
              textTitle: "担当者名",
              textContent:
                (stores?.rep_name ?? "") + " " + (stores?.rep_name_first ?? ""),
            },
            {
              textTitle: "住所",
              textContent:
                (stores?.address?.num1 ?? "") +
                (stores?.address?.num2 ?? "") +
                " " +
                (stores?.address?.region ?? "") +
                (stores?.address?.city ?? "") +
                " " +
                (stores?.address?.town ?? "") +
                " " +
                " " +
                (stores?.address?.building_name ?? ""),
            },
          ]
          setStore({
            name_company: response.data.stores.name,
            dataInfo: dataInfo,
          })
        } else {
          navigate(
            "/super-sales-commission-setting-company/?pageCurrent=1&pageSize=10"
          )
        }
        getListColections()
      } else {
        getListColections()
      }
    }
  }, [storeId, activeKey])

  useEffect(() => {
    getInfoStore()
  }, [getInfoStore])

  const propsFee = {
    storeId,
    activeKey,
    setActiveKey,
  }

  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>掲載管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/super-sales-commission-setting-company/?pageCurrent=1&pageSize=10">
                手数料設定
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="text-[#888888]">
                {storeId === "all"
                  ? "全企業販売手数料設定"
                  : `企業別販売手数料設定 －（${store?.name_company}）`}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Typography.Title
            level={2}
            className="!text-2xl !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
          >
            {storeId === "all"
              ? "全企業販売手数料設定"
              : `企業別販売手数料設定 －（${store?.name_company}）`}
          </Typography.Title>
        </div>
        {storeId !== "all" && <TableDetail dataInfo={store.dataInfo} />}
        <div className="mt-[19px]">
          <Tabs
            activeKey={activeKey}
            size="large"
            className="border"
            tabBarStyle={{
              paddingLeft: 20,
            }}
            onChange={(e) => {
              setActiveKey(e)
            }}
          >
            <TabPane tab="生産者・メーカー・卸向け手数料" key="1">
              <div>
                <HeaderTab
                  type="wholesale"
                  {...propsFee}
                  storeId={storeId}
                  listColections={listColections}
                />
                {storeId == "all" ? (
                  <ApplicationfeeAll
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    listColections={listColections}
                    setListColections={setListColections}
                    getListColections={getListColections}
                    type="wholesale"
                  />
                ) : (
                  <Applicationfee
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    listColections={listColections}
                    setListColections={setListColections}
                    getListColections={getListColections}
                    storeId={storeId}
                    type="wholesale"
                  />
                )}
                {/* <FooterSetting /> */}
              </div>
            </TabPane>
            <TabPane tab="飲食店・小売店向け手数料" key="2">
              <div>
                <HeaderTab
                  {...propsFee}
                  type="retail"
                  storeId={storeId}
                  listColections={listColections}
                />
                {storeId == "all" ? (
                  <ApplicationfeeAll
                    isLoading={isLoading}
                    listColections={listColections}
                    setIsLoading={setIsLoading}
                    setListColections={setListColections}
                    getListColections={getListColections}
                    type="retail"
                  />
                ) : (
                  <Applicationfee
                    isLoading={isLoading}
                    listColections={listColections}
                    setIsLoading={setIsLoading}
                    setListColections={setListColections}
                    getListColections={getListColections}
                    storeId={storeId}
                    type="retail"
                  />
                )}
                {/* <FooterSetting /> */}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </div>
  )
}

export default Setting
