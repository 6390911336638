import { Col, Row } from "antd"
import React from "react"

const RowInfo = ({ textTitle, textContent, borderTop }) => {
  return (
    <div className={`${!borderTop && "borderTop"}`}>
      <Row
        style={{
          height: 35,
        }}
      >
        <Col
          span={3}
          style={{
            background: "#FAFAFA",
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>{textTitle}</div>
        </Col>
        <Col style={{ display: "flex", alignItems: "center", paddingLeft: 20 }}>
          <span>{textContent}</span>
        </Col>
      </Row>
    </div>
  )
}

export default RowInfo
